<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';
import WebsiteSearch from "@/components/nestedSearch/nested-search.vue";
import Modal from 'bootstrap/js/dist/modal';


export default {
    props: {

        title: String
    },
    components: {
        Layout,
        PageHeader,
        WebsiteSearch,
    },

    data() {
        return {
            trackedPagesData: [],
            dialogTitle: "",
            dialogMessage: ""
        };
    },



    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedOrganisationId'
        ])
    },

    methods: {

        handleOnPageSelected(pageInfoObj) {
            console.log("trackPAgeIfno", pageInfoObj);
            console.log(pageInfoObj.id);

            api.trackPage(this.selectedOrganisationId, this.selectedWebisteId, pageInfoObj.id)
                .then(() => {
                    this.dialogTitle = "Added page to track"
                    this.dialogMessage = `Now trackinng page ${pageInfoObj.path}`;

                    var myModal = new Modal(document.getElementById('myModal'))
                    myModal.show();
                })
                .catch(() => {
                    this.dialogTitle = "Error adding page to track"
                    this.dialogMessage = `Sorry, we could not add ${pageInfoObj.path} to trac. Please try again.`;

                    var myModal = new Modal(document.getElementById('myModal'))
                    myModal.show();
                });
        }
    }
}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" />

        <div class="row">
            <div class="col">

                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                            text-uppercase
                            fw-bold
                            text-truncate
                            mb-0
                            ">
                            Select page to track
                        </p>
                    </div>
                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                            
                                <!-- staticBackdrop Modal -->
                                <div id="myModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
                                    tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="myModalLabel"> {{ dialogTitle }}</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                    aria-label="Close"> </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>{{ dialogMessage }}</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-light"
                                                    data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end staticb ackdrop Modal-->

                                <p>Select a page to track and we will monitor SERP changes for that page.</p>

                                <WebsiteSearch @onPageSelected="handleOnPageSelected" :websiteId="selectedWebisteId">
                                </WebsiteSearch>

                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>


    </Layout>
</template>